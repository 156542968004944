import React from "react";

const features = [
  {
    name: "Set up your account in 10 minutes.",
    icon: "1",
  },
  {
    name: "Create your checkouts and start selling.",
    icon: "2",
  },
  {
    name: "See your conversions and sales skyrocket 🚀",
    icon: "3",
  },
];

export default function Success() {
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold text-indigo-600 sr-only">
            Sell online with CheckoutJoy.
          </h2>
          <p className=" text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your path to selling more online:
          </p>
        </div>
        <div className="mx-auto mt-8 max-w-2xl lg:max-w-none">
          <dl className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-xl sm:text-3xl font-semibold text-gray-900">
                  <span className="text-indigo-500 font-extrabold text-5xl sm:text-7xl">
                    {feature.icon}
                  </span>
                  {feature.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
        <div className="mt-20 flex items-center justify-center">
          <a
            href="/signup"
            className="shadow inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
          >
            Start free 14-day trial
          </a>
        </div>
      </div>
    </div>
  );
}
