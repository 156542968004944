import React from "react";

const platforms = [
  {
    image: "https://assets.checkoutjoy.com/site/kajabi-logo.svg",
    alt: "Kajabi payment options",
    link: "/kajabi",
  },
  {
    image: "https://assets.checkoutjoy.com/site/thinkific-logo-600.png",
    alt: "Thinkific payment options",
    link: "/thinkific",
  },
  {
    image: "https://assets.checkoutjoy.com/site/teachable7122.png",
    alt: "Teachable payment options",
    link: "/teachable",
  },
  {
    image:
      "https://d1aettbyeyfilo.cloudfront.net/Kartra/5001051_1563477842072Kartra-LogoPNG.png",
    alt: "Kartra payment options",
    link: "/kartra",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/instapage-logo-black.webp",
    alt: "instapage payment options",
    link: "/platforms",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/webflow.webp",
    alt: "webflow payment options",
    link: "/platforms",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/wordpress.webp",
    alt: "wordpress payment options",
    link: "/platforms",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/framer.webp",
    alt: "framer payment options",
    link: "/platforms",
  },
];

export default function PlatformSelect() {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
        <div className="mx-auto w-full max-w-xl lg:mx-0">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
            Embed checkouts on any landing page
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Create hassle-free checkouts for your online courses, memberships,
            and digital products, no matter where they're hosted. Embed our
            checkout widget to sell from anywhere.
          </p>
        </div>
        <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8 gap-x-6">
          {platforms.map((platform, index) => {
            return (
              <div className="flex items-center justify-center">
                <img
                  key={index}
                  className="max-h-12 w-full object-contain"
                  src={platform.image}
                  alt={platform.alt}
                  width={105}
                  height={48}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
