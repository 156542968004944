import React, { useEffect } from "react";
import Head from "next/head";
import Glide from "@glidejs/glide";
import { withApollo } from "utils/apollo";
import ReactPlayer from "react-player/lazy";
import PlatformSelect from "components/Website/PlatformSelect";
import { Testimonials } from "components/Marketing/Testimonials";
import { Hero } from "../Website/Hero";
import PaymentProcessors from "../Website/PaymentProcessors";
import Features from "../Website/Features";
import Success from "../Website/Success";
import Page from "./Page";
import { TypeAnimation } from "react-type-animation";
import PlatformIntegrations from "./PlatformIntegrations";

const HomePage = () => {
  useEffect(() => {
    new Glide(".glide", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      autoplay: 3000,
    }).mount();
  }, []);

  return (
    <Page mainClasses="sm:-mt-16 mx-auto">
      <Head>
        <title>CheckoutJoy - Next-level checkouts for online businesses</title>
        <meta
          name="description"
          content="The easiest way to create advanced, tax-compliant checkouts."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.checkoutjoy.com/`} />
        <meta property="fb:app_id" content={"352507272440982"} />
        <meta property="og:site_name" content={"CheckoutJoy"} />
        <meta
          property="og:title"
          content={
            "Checkouts and storefronts to sell anything online. Accept payments with your local payment methods."
          }
        />
        <meta
          property="og:description"
          content={
            "Checkouts and storefronts to sell anything online. Accept payments with your local payment methods."
          }
        />
        <meta
          property="og:image"
          content={`https://assets.checkoutjoy.com/og/og.png`}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="Sell anything online and accept payments with your local payment methods."
        />
        <link rel="stylesheet" href="/glide.core.min.css" />
      </Head>

      <Hero />

      <div className="relative pb-16 bg-white overflow-hidden">
        {/*<Problems/>*/}
        <div className="relative isolate  px-6  lg:px-8 py-20 bg-gray-100 ">
          <div className="mx-auto max-w-3xl">
            <div className="text-center">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                CheckoutJoy is the easiest way to create <br />
                <TypeAnimation
                  sequence={[
                    "storefronts",
                    1000,
                    "checkout pages",
                    1000,
                    "embedded checkouts",
                    1000,
                    "payment links",
                    1000,
                    "link-in-bio pages",
                    1000,
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-indigo-700 font-extrabold"
                  repeat={Infinity}
                />
                <br />
                for any website.
              </h1>
            </div>
          </div>
        </div>

        <PlatformIntegrations />

        <div className="relative bg-white pt-16 pb-24   overflow-hidden lg:text-center px-4 sm:px-6 ">
          <PlatformSelect />
        </div>
        <div className="relative bg-white pt-16 pb-24 overflow-hidden lg:text-center px-4 sm:px-6 ">
          <PaymentProcessors />
        </div>

        <Success />

        <div id="features" className="bg-gray-100 ">
          <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8 py-24">
              <div className="lg:col-span-1">
                <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Use your local payment methods.
                  <br /> <br />
                  Sell in any currency. <br /> <br /> Give your customers the
                  payment options they know and trust.
                </h3>
              </div>
              <div className="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0">
                <div className="mt-10 sm:mt-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div className="mt-5">
                    <h4 className="text-xl leading-6 font-medium text-gray-900">
                      Trusted payment options
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Add any of our supported payment options to your
                      checkouts. Let your customers pay using payment options
                      that they know and trust.
                    </p>
                  </div>
                </div>

                <div className="mt-10 sm:mt-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div className="mt-5">
                    <h4 className="text-xl leading-6 font-medium text-gray-900">
                      Automatic Sales Tax
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Automate your tax compliance - calculate and collect
                      taxes, generate and send invoices, and generate tax
                      reports.
                    </p>
                  </div>
                </div>

                <div className="mt-10 sm:mt-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div className="mt-5">
                    <h4 className="text-xl leading-6 font-medium text-gray-900">
                      Easy to use
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Create checkout pages or embed custom checkouts on any
                      page within minutes.{" "}
                      <span className="font-bold">No coding required.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="bg-indigo-600 overflow-hidden px-4 sm:px-6 ">
          <div className="relative max-w-7xl mx-auto sm:pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="https://assets.checkoutjoy.com/site/stefchris.png"
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="h-12 w-12 text-white opacity-25 transform -translate-x-4 translate-y-6 "
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl leading-9 font-medium text-white">
                    <p className="mt-6 text-2xl font-medium text-white">
                      We battled to find a solution for accepting local payments
                      that integrates with our Kajabi website, and we lost a lot
                      of sales due to this.
                    </p>
                    <p className="mt-6 text-2xl font-medium text-white">
                      CheckoutJoy solved this problem and now 60% of our sales
                      go through our local payment gateway!
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="h-12 w-12 rounded-full"
                          src="https://assets.checkoutjoy.com/site/stefchris.png"
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <p className="text-base font-medium text-white">
                          Christelle Janse van Rensburg
                        </p>
                        <p className="text-base font-medium text-indigo-100">
                          Creative Crafting Club
                        </p>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <Features />
        <div className="mb-16 flex items-center justify-center">
          <a
            href="/signup"
            className="shadow inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
          >
            Start free 14-day trial
          </a>
        </div>

        <section className="bg-gray-900 py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                <figure className="mt-10 flex flex-auto flex-col justify-between">
                  <blockquote className="text-lg leading-8 text-white font-medium">
                    <p>
                      “The ability to include an EU conform VAT that's inclusive
                      in the price is a life-saving feature! I was really
                      struggling to find something that would do the job without
                      a million zapier integrations.”
                    </p>
                  </blockquote>
                  <figcaption className="mt-10 flex items-center gap-x-6">
                    <img
                      className="h-14 w-14 rounded-full bg-gray-800"
                      src="https://assets.checkoutjoy.com/testimonials/a.jpg"
                      alt=""
                    />
                    <div className="text-base">
                      <div className="font-semibold text-white">
                        Anastasia Ushakova
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
              <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                <figure className="mt-10 flex flex-auto flex-col justify-between">
                  <blockquote className="text-lg leading-8 text-white font-medium">
                    <p>
                      “Really love the tool and the support team. Well
                      recommended by Thinkific. Good tool to manage multiple
                      payment options for our landing pages in one dashboard.”
                    </p>
                  </blockquote>
                  <figcaption className="mt-10 flex items-center gap-x-6">
                    <img
                      className="h-14 w-14 rounded-full bg-gray-800"
                      src="https://assets.checkoutjoy.com/icons/dav.jpg"
                      alt=""
                    />
                    <div className="text-base">
                      <div className="font-semibold text-white">
                        Davzon (Davzon Pvt. Ltd.)
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-indigo-900 pt-8 py-16 ">
          <figure className="mx-auto max-w-2xl">
            <p className="sr-only">5 out of 5 stars</p>
            <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-white sm:text-2xl sm:leading-9 text-center">
              <p>
                “Checkoutjoy is beautifully designed and super easy to set up
                and use. Support is top class - friendly, prompt, and helpful.
                Integrates perfectly with my Kartra setup. Fantastic!”
              </p>
            </blockquote>
            <figcaption className="mt-10 flex items-center gap-x-6">
              <img
                className="h-12 w-12 rounded-full bg-gray-50"
                src="https://assets.checkoutjoy.com/site/k-headshot-1.jpg"
                alt=""
              />
              <div className="text-sm leading-6">
                <div className="font-semibold text-white">Keith</div>
                <div className="mt-0.5 text-gray-200">Eslbase.com</div>
              </div>
            </figcaption>
          </figure>
        </section>
        <Testimonials />
        <div className="bg-gray-100 pb-16 flex items-center justify-center">
          <a
            href="/signup"
            className="shadow inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
          >
            Start free 14-day trial
          </a>
        </div>

        <div className="pb-16 bg-indigo-600 lg:pb-0 lg:z-10 lg:relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="lg:col-span-1 flex items-center">
              <div className="rounded-2xl">
                <ReactPlayer
                  className="rounded"
                  controls={true}
                  loop={true}
                  playing={true}
                  volume={0.8}
                  muted={true}
                  // light
                  playIcon={
                    <svg
                      className="h-20 w-20 text-indigo-500"
                      fill="currentColor"
                      viewBox="0 0 84 84"
                    >
                      <circle
                        opacity="0.9"
                        cx={42}
                        cy={42}
                        r={42}
                        fill="white"
                      />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  }
                  url="https://assets.checkoutjoy.com/site/mitesh-testimonial-1.mp4"
                />
              </div>
            </div>
            <div className="mt-12 lg:m-0 lg:col-span-1 lg:pl-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                <blockquote>
                  <div>
                    <svg
                      className="h-12 w-12 text-white opacity-25"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="mt-6 text-2xl font-medium text-white">
                      ..I've been using Kajabi for my course platform for a
                      couple of years now and I've been having a big headache
                      trying to use the Kajabi payment checkouts.
                    </p>
                    <p className="mt-6 text-2xl font-medium text-white">
                      But for the first time I got a solution with CheckoutJoy
                      and I must say CheckoutJoy was the best decision I've ever
                      taken for my business. Life has been so peaceful, payments
                      get done easily, no dropouts happening anymore..
                    </p>
                  </div>
                  <footer className="mt-6">
                    <p className="text-base font-medium text-white">
                      Mitesh Khatri
                    </p>
                    <p className="text-base font-medium text-indigo-100">
                      <a href="https://coaching.miteshkhatri.com/">
                        miteshkhatri.com
                      </a>
                    </p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="relative bg-white pt-16 pb-32 overflow-hidden px-4 sm:px-6 ">
            <div className="text-center">
              <p className="mb-8 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Integrated checkouts that work seamlessly with your existing
                tools
              </p>
            </div>

            <div className="relative sm:mt-16 lg:mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 lg:col-start-2">
                  <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                    Add local payment options to your checkout pages.
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Take payments with your local payment gateway straight from
                    your checkout pages.
                  </p>

                  <h4 className="mt-4 text-lg font-extrabold text-gray-900 tracking-tight">
                    Simple setup in less than 10 minutes.
                  </h4>
                  <dl className="mt-10 space-y-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                          1
                        </div>
                      </div>
                      <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                          Sign up for a free trial
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          Sign up for a free trial and set up your payment
                          gateway details.
                          <p>
                            Follow our easy step-by-step guides to complete your
                            setup in less than 10 minutes.
                          </p>
                        </dd>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                          2
                        </div>
                      </div>
                      <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                          Set up your payment gateway & add your offers
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          Import your existing offers so that we know how to
                          charge your customers. Set up once-off payments,
                          multiple payments or recurring memberships. We will
                          even convert the currency to your local currency if
                          you want!
                        </dd>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                          3
                        </div>
                      </div>
                      <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                          Embed our plugin on your website
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          Copy a few lines of code and you're all set. Your
                          payment button will now be displayed on your checkout
                          pages, allowing your customers to securely pay with
                          options they know and trust.
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <div className="glide">
                    <div data-glide-el="track" className="glide__track">
                      <ul className="glide__slides">
                        <li className="glide__slide">
                          <img
                            className="relative mx-auto shadow-lg rounded-lg"
                            width={490}
                            src="https://assets.checkoutjoy.com/site/checkout-box-once.png"
                          />
                        </li>
                        <li className="glide__slide">
                          <img
                            className="relative mx-auto shadow-lg rounded-lg"
                            width={490}
                            src="https://assets.checkoutjoy.com/site/checkout-example-1.png"
                          />
                        </li>
                        <li className="glide__slide">
                          <img
                            className="relative mx-auto shadow-lg rounded-lg"
                            width={490}
                            src="https://assets.checkoutjoy.com/site/checkout-example-2.png"
                          />
                        </li>
                        <li className="glide__slide">
                          <img
                            className="relative mx-auto shadow-lg rounded-lg"
                            width={490}
                            src="https://assets.checkoutjoy.com/site/checkout-example-3.png"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                  <div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        More Successful Checkouts{" "}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        Tired of the rigid checkout page designs of your
                        e-commerce platform? Use our custom checkout pages to
                        make sure your customers convert. Connect your own
                        domain, use any of our supported payment gateways,
                        multiple currencies and currency conversions and best of
                        all - integrated sales taxes with Quaderno.
                      </p>
                      <div className="mt-6 flex">
                        <a
                          href="/signup"
                          className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Start free trial
                        </a>
                        <a
                          href="/custom-checkout-pages"
                          className="inline-flex items-center px-4 py-2 border border-transparent bg-transparent text-base font-medium rounded-md text-indigo-600 hover:text-indigo-700"
                        >
                          Learn more &rarr;{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://assets.checkoutjoy.com/site/checkout-1.gif"
                      alt="Inbox user interface"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        Sales Taxes & Invoices
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        Automatically calculate sales taxes based on your
                        customer's billing address and generate compliant
                        invoices on checkout. Don't let any checkout challenge
                        stand between your business and closing that sale.
                      </p>
                      <div className="mt-6 flex">
                        <a
                          href="/kajabi-quaderno"
                          className="inline-flex items-center px-4 py-2 border border-transparent bg-transparent text-base font-medium rounded-md text-indigo-600 hover:text-indigo-700"
                        >
                          Learn more &rarr;{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://assets.checkoutjoy.com/site/checkout-2.png"
                      alt="Customer profile user interface"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="affiliate-platform" className="relative mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                  <div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        Affiliate marketing platform
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        Easily create, track and grow your own affiliate
                        marketing programs. Our affiliate tracking integrates
                        seamlessly with your checkouts.
                      </p>
                      <div className="mt-6">
                        <a
                          href="/signup"
                          className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Start free trial
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://assets.checkoutjoy.com/site/aff.png"
                      alt="Affiliate marketing platform"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        Real-time reports and analytics
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        Track your checkout page performance with our real-time
                        metrics and reporting. Page views, checkout session
                        duration, conversion rates and much more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:w-full lg:h-auto lg:max-w-none"
                      src="https://assets.checkoutjoy.com/site/reports-dashboard.png"
                      alt="Kajabi checkout reports on CheckoutJoy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      <div className="bg-indigo-700">
        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
            Ready to dive in?
            <br />
            Start your free 14-day trial today.
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/signup"
                className="shadow inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
              >
                Start Free Trial
              </a>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default withApollo(HomePage);
