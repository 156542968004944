import React from "react";
import HomePage from "components/Layouts/HomePage";
import Head from "next/head";

function IndexPage() {

    return <>
        <Head>
            <meta name="facebook-domain-verification" content="ljeuk6ipq146hkylumfwwwukiv7y0b"/>
        </Head>
        <HomePage/>
    </>
}

export async function getStaticProps() {
    const pricing = await fetch("https://assets.checkoutjoy.com/pricing.json").then((r: any) => r.json());
    return {props: {pricing}}
}

export default IndexPage;
